@import "normalize.css";

body {
  margin: 0;
  font-family: muli, sans-serif;
  font-size: 21px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #3d3d3d;
}

p {
  margin: 0;
  line-height: 1.5;
}

@media only screen and (max-width: 600px) {
  body {
    font-size: 16px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
